<script lang="ts">
  import {SuggestType} from "../../constants/suggestType";
  import SuggestionList from "./SuggestionList.svelte";
  import TrendingQueryList from "./TrendingQueryList.svelte";
  import NavSlider from "./navSlider/NavSlider.svelte";
  import History from "./history/History.svelte";
  import {suggestType} from "../../store/Derived";
  import {SqrlTestId} from "../../constants/squirrel";
</script>

<div
  class="squirrel_searchSuggestionsContainer js_squirrel_searchSuggestionsContainer"
  data-qa-id="search-suggestions"
  data-suggestserveruri="/san-squirrel-suggest-api/completion"
  data-testid={SqrlTestId.SUGGEST_LAYER}
>
  {#if $suggestType === SuggestType.SUGGEST}
    <SuggestionList on:sendRequest />
  {:else if $suggestType === SuggestType.HISTORY}
    <NavSlider />
    <History />
    <TrendingQueryList />
  {:else if $suggestType === SuggestType.TRENDING_QUERY}
    <NavSlider  />
    <TrendingQueryList />
  {/if}
</div>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/breakpoint" as breakpoint;
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_searchSuggestionsContainer {
    position: absolute;
    top: 56px;
    left: 0;
    z-index: 101;
    width: 100vw;
    height: auto;
    background-color: pl_variables.$pl_color-grey25;
    overflow-x: auto;
    border-bottom: 1px solid #c4c4c4;

    @include breakpoint.from-l($to: false) {
      top: 72px;
      left: -1px;
      width: calc(100% + 2px);
      border-radius: 0 0 8px 8px;
      border-right: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      border-left: 1px solid #c4c4c4;
    }
  }
</style>
