/*                                                           */
/*                      */
/*                */
/*                */
import type {Response} from '../models/Response';
import type {SuggestResponse} from '../models/SuggestResponse';
import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';

export class IndexControllerService {
    /**
 *
 *
 *
 *
 *
 */
    public static submit(
        q: string,
        history?: string,
        c?: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/san-squirrel-suggest-api/submit',
            query: {
                'q': q,
                'history': history,
                'c': c,
            },
        });
    }

    /**
 *
 *
 *
 *
 *
 *
 */
    public static query(
        q: string,
        history?: string,
        c?: number,
        numVisibleHistoryTerms?: number,
    ): CancelablePromise<(Response | SuggestResponse)> {
        let queryRequestParam: Record<string, any> = {
            'q': q,
            'history': history,
            'c': c
        };
        if (numVisibleHistoryTerms !== undefined) {
            queryRequestParam['positionOffset'] = numVisibleHistoryTerms;
        }
        return __request(OpenAPI, {
            method: 'GET',
            url: '/san-squirrel-suggest-api/completion',
            query: queryRequestParam,
        });
    }
}
