import type { QBus } from "@otto-ec/global-resources/event-q-bus";
import type { DresonResolvedData, FacetValueTagsLoadedData } from "./types/global";
import { eventQBus } from "@otto-ec/event-q-bus";
import { trackingStore } from "./store/TrackingStore";
import { tlSearchBoxActionCharacter } from "./tracking/labels/SearchBoxAction";
import { searchfieldStore, value } from "./store/SearchfieldStore";
import { encodedHistory, isEmpty, submitHistory } from "./store/HistoryStore";
import { SQRL_EVENT } from "./events/sqrlEvents";
import { navSlider } from "./components/suggestLayer/navSlider/NavSlider";
import { sqrlRequest } from "./util/SqrlRequest";
import { get } from "svelte/store";
import { sqrlConsentBanner } from "./util/SqrlConsentBanner";
import type {EventContextResponse} from "./types/tracking";

export class SearchHandler {
  private bus: QBus = eventQBus();

  /**
 *
 *
 *
 *
 *
 */
  fetchSearchTerm() {
    this.bus.onModuleLoaded("squirrel.stomachshop.searchbar", ["reptile.turtle.body-js"], () => {
      const searchterm = document
        .querySelector("#reptile-searchterm")
        ?.getAttribute("data-searchterm");
      if (searchterm) {
        trackingStore.$sendTrackingLabelOnFocus = false;
        tlSearchBoxActionCharacter.setIsTrackable(false);
        searchfieldStore.$value = searchterm;
      }
    });
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  onAllPreloadedScriptsLoaded() {
    import("@otto-ec/global-resources/event-loader").then(({ eventLoader }) => {
      eventLoader.onAllPreloadScriptsLoaded(120, async () => {
        submitHistory.reInitialize();

        sqrlRequest.fetch(
          get(value),
          get(value).length,
          get(isEmpty) ? undefined : encodedHistory(),
          undefined,
          true,
        );

        this.bus.on("ftfind.dresonRule.resolved", ({ rule, redirect }: DresonResolvedData) =>
          submitHistory.updateTarget(rule, redirect),
        );

        this.bus.on("heureka.facetValueTags.loaded", ({ rule, tags }: FacetValueTagsLoadedData) =>
          submitHistory.updateTags(rule, tags),
        );
      });
    });
  }

  initNavSlider() {
    this.bus.on(SQRL_EVENT.SEARCH_FOCUS, (mergeContext: string) => {
      navSlider.onMergeEventContext(mergeContext);
    });
  }

  init() {
    sqrlConsentBanner.listenOnConsentChange();
    this.fetchSearchTerm();
    this.onAllPreloadedScriptsLoaded();
    this.initNavSlider();
  }
}

export const searchHandler = new SearchHandler();
