import {derived, get, type Writable, writable} from "svelte/store";
import type {HistoryItem} from "../types/global";
import {sqrlStorage} from "../util/SqrlStorage";
import {STORAGE} from "../constants/storage";
import {sqrlConsentBanner} from "../util/SqrlConsentBanner";
import * as protobufHistory from "@otto-ec/squirrel_protobuf-history";
import * as historyStore from "./HistoryStore";
import {keywords, original, suggestionStore} from "./SuggestionStore";
import {visualSearches} from "./HistoryStore";
import {value} from "./SearchfieldStore";
import {util} from "../util/util";
import {SubmitStore} from "./SubmitStore";

/**
 *
 */
class TrendingQueriesStore {
    private readonly maxTrendingQueriesWithHistory: number = 7;

    trendingQueries = derived(
        [
            visualSearches,
            suggestionStore.keywords
        ],
        (
            [
                $visualSearches,
                $keywords
            ]
        ) => {
            /*                                        */
            if ($visualSearches.length === 0) return $keywords;

            /*                                                                                                       */
            /*                                          */
            return $keywords.slice(0, this.maxTrendingQueriesWithHistory - $visualSearches.length);
        }
    )
}


export const trendingQueriesStore = new TrendingQueriesStore();
export const trendingQueries = trendingQueriesStore.trendingQueries;